<template>
  <div
    class="base-document relative"
    :class="{
      'is-disabled': disabled,
      'shadow-6': shadow,
      'is-landscape': landscape
    }"
    @mouseenter="toggleHover(true)"
    @mouseleave="toggleHover(false)"
  >
    <div :class="{ 'is-exporting': exporting }" class="base-document-content">
      <transition name="fade" mode="out-in">
        <div
          v-if="!loading"
          :key="'invoice-document-loaded'"
          class="document-loaded"
          :class="{ 'all-content-loaded': allContentLoaded }"
        >
          <div class="document-header">
            <slot name="header" />
          </div>

          <div class="document-body">
            <transition name="fade" mode="out-in">
              <div
                v-if="!loadingBody"
                :key="'loaded-body'"
                class="document-body-loaded"
              >
                <slot />
              </div>
              <div v-else :key="'loading-body'">
                <slot name="loading-body">
                  <loader-box height="500px" />
                </slot>
              </div>
            </transition>
          </div>

          <div class="document-corner-status text-dark-silver text-right">
            <transition name="fade">
              <template v-if="locked">
                <el-tooltip
                  v-if="canUnlock"
                  content="Editing Invoice is locked. Click to unlock"
                  effect="light"
                >
                  <div
                    class="p-3 hover:bg-silver cursor-pointer"
                    @click="$emit('unlock')"
                  >
                    <icon :icon="lockIcon" class="text-2xl" />
                  </div>
                </el-tooltip>
              </template>
              <div v-else-if="showMenu && isHovering" class="document-menu">
                <el-tooltip
                  content="Toggle layout between portrait and landscape"
                >
                  <action-icon
                    class="landscape-icon text-dark-silver"
                    :icon="landscapeIcon"
                    icon-size="2em"
                    :rotation="isLandscape ? 90 : null"
                    @click="toggleLandscape(!isLandscape)"
                  />
                </el-tooltip>
              </div>
            </transition>
            <transition name="fade">
              <div v-if="saving">
                Saving Document...
                <icon :icon="savingIcon" spin />
              </div>
            </transition>
          </div>
        </div>
        <div v-else :key="'document-loading'" class="document-loading">
          <loader-box class="loading-document" height="980px" />
        </div>
      </transition>
    </div>

    <div v-if="exporting" class="exporting-overlay">
      <div
        class="text-lg bg-white text-center shadow-10 rounded-lg px-8 py-5 h-42"
      >
        <icon
          :icon="exportIcon"
          class="text-3xl text-dark-silver animated loop tada"
          spin
        />
        <div class="mt-5">Exporting... Please wait.</div>
        <div class="mt-3">This may take up to 60 seconds</div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionIcon from '@/components/Core/ActionIcon';
import { onAllImagesLoaded } from '@/utils/helpers';

import {
  arrowsCw as savingIcon,
  lock as lockIcon,
  rotateDoc as landscapeIcon,
  shareSquareO as exportIcon
} from '@/vendor/icons';
import LandscapeStyle from './landscape.lazyscss';
import PortraitStyle from './portrait.lazyscss';

export default {
  components: { ActionIcon },
  props: {
    showMenu: Boolean,
    canUnlock: Boolean,
    disabled: Boolean,
    exporting: Boolean,
    loading: Boolean,
    loadingBody: Boolean,
    locked: Boolean,
    saving: Boolean,
    shadow: Boolean,
    landscape: Boolean,
    title: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isHovering: false,
      isLandscape: this.landscape,
      allContentLoaded: false,

      // Icons
      exportIcon,
      landscapeIcon,
      lockIcon,
      savingIcon
    };
  },
  watch: {
    landscape() {
      this.toggleLandscape(this.landscape);
    }
  },
  mounted() {
    if (this.isLandscape) {
      LandscapeStyle.use();
    } else {
      PortraitStyle.use();
    }

    // Give ample time for all animations / components to render, then make sure all images have loaded
    setTimeout(
      () => onAllImagesLoaded(() => (this.allContentLoaded = true)),
      3000
    );
  },
  methods: {
    toggleHover(isHovering) {
      this.isHovering = isHovering;
      this.$emit('update:hovering', isHovering);
      this.$emit('hover', isHovering);
    },
    toggleLandscape(isLandscape) {
      this.isLandscape = isLandscape;

      try {
        PortraitStyle.unuse();
      } catch (e) {}

      try {
        LandscapeStyle.unuse();
      } catch (e) {}

      if (isLandscape) {
        LandscapeStyle.use();
      } else {
        PortraitStyle.use();
      }

      this.$emit('update:landscape', isLandscape);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables.scss';

.is-exporting {
  opacity: 0.5;
}

.base-document /deep/ {
  position: relative;
  font-size: 14px;
  background: white;
  padding: 60px;
  transition: all 0.5s;

  table.doc-table {
    border-collapse: collapse;

    & > thead,
    & > tbody,
    & > tfoot {
      & > tr {
        & > td {
          padding: 4px 10px;

          &.indented {
            padding-left: 20px;
          }

          &.merged-start-col {
            border-right: none;
          }

          &.merged-middle-col {
            border-right: none;
            border-left: none;
          }

          &.merged-end-col {
            border-left: none;
          }
        }

        &.merged-start-row {
          & > td {
            border-bottom: none;
          }
        }

        &.merged-middle-row {
          & > td {
            border-bottom: none;
          }
        }

        &.merged-end-row {
          & > td {
            border-top: none;
          }
        }
      }
    }

    & > thead,
    & > tbody {
      & > tr {
        & > td {
          border: 1px solid $color-gray;
        }
      }
    }

    & > thead {
      & > tr {
        & > td {
          background: $color-baby-blue;
          color: $color-blue;
        }

        &.doc-table-pre-header {
          td {
            background: white;
            color: $color-dark-gray;
            font-weight: 600;
          }
        }
      }
    }

    & > tbody {
      & > tr.sub-row-header {
        & > td {
          border-color: white;

          &:first-child {
            border-left-color: $color-gray;
            font-weight: bold;
            font-size: 1rem;
          }

          &:last-child {
            border-right-color: $color-gray;
          }
        }
      }

      & > tr.sub-row {
        & > td {
          border-color: white;

          &:first-child {
            border-left-color: $color-gray;
            padding-left: 2em;
          }

          &:last-child {
            border-right-color: $color-gray;
          }
        }
      }

      & > tr.footer-row {
        & > td {
          color: $color-dark-gray;
          font-weight: bold;
          background: $color-light-silver;
        }
      }
    }
  }

  .document-corner-status {
    position: absolute;
    top: 1em;
    right: 2em;
  }

  .exporting-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10em;
    opacity: 1 !important;
  }
}

.base-document.is-disabled > div {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>

<style lang="scss">
@page {
  // Enable the Background color printing by default
  -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
  color-adjust: exact !important; /*Firefox*/
}

@media print {
  #loading-app,
  .exporting-overlay,
  .el-notification {
    display: none !important;
  }

  html,
  body,
  .main,
  .content {
    display: block !important;
    width: 100% !important;
    margin: 1em 1em !important;
    padding: 0 !important;
    overflow: visible !important;
    min-width: 0 !important;
  }

  #app {
    position: relative !important;
    margin: auto !important;
    overflow: visible !important;
    height: auto !important;

    .base-document {
      padding: 0;
      min-height: 0;
      box-shadow: none;

      .dashed-outline {
        outline: 0;
      }

      .document-corner-status {
        display: none;
      }
    }

    table {
      page-break-inside: auto;
    }

    tr {
      page-break-inside: avoid;
      page-break-after: auto;
    }

    .page-break {
      page-break-after: always;
    }
  }
}
</style>
