import { render, staticRenderFns } from "./BaseDocument.vue?vue&type=template&id=76d1a857&scoped=true&"
import script from "./BaseDocument.vue?vue&type=script&lang=js&"
export * from "./BaseDocument.vue?vue&type=script&lang=js&"
import style0 from "./BaseDocument.vue?vue&type=style&index=0&id=76d1a857&lang=scss&scoped=true&"
import style1 from "./BaseDocument.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76d1a857",
  null
  
)

export default component.exports